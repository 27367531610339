import {
  SetItemType,
  StorageKeys,
  StorageToUse,
  SyncStorageData,
} from "./app-storage.constants";

class AppStorage {
  private updateSyncStorageData = () => {
    const payload: SyncStorageData = {
      lastChangeDate: new Date().toISOString(),
      lastRecource: StorageToUse.LocalStorage,
    };

    this.setItem(StorageKeys.StorageData, payload);
  };

  private parseData = (
    str?: string | null,
  ): SetItemType | string | undefined | number => {
    if (!str) return;
    try {
      const data = JSON.parse(str);
      return data;
    } catch (e) {
      return str;
    }
  };

  public getItem = (
    key: StorageKeys,
  ): string | number | SetItemType | undefined => {
    const data = localStorage.getItem(key);

    if (data) return this.parseData(data);
    return undefined;
  };

  public removeItem = (key: StorageKeys) => {
    localStorage.removeItem(key);
  };

  public setItem = (
    key: StorageKeys,
    payload?: string | number | SetItemType,
  ) => {
    if (!payload) {
      this.removeItem(key);
      return;
    }

    const payloadFormated =
      typeof payload === "string" ? payload : JSON.stringify(payload);
    localStorage.setItem(key, payloadFormated);
    if (key !== StorageKeys.StorageData) this.updateSyncStorageData();
  };
}

export default new AppStorage();
