import { tick } from "../state.ts";
import {
  $energyRecoverPerTick,
  $$profitPerTick,
  $energy,
  $maxEnergy,
  $balanceCoins,
} from "../gameDomain.ts";
import { sample } from "effector";
import { $turboBoostCoef } from "../boosts.ts";

export const initTickEvents = () => {
  sample({
    source: {
      maxEnergy: $maxEnergy,
      energy: $energy,
      energyRecoverPerTick: $energyRecoverPerTick,
    },
    clock: tick,
    fn: ({ maxEnergy, energy, energyRecoverPerTick }) => {
      if (energy >= maxEnergy) {
        return energy;
      }
      if (energy + energyRecoverPerTick > maxEnergy) {
        return maxEnergy;
      }
      return energy + energyRecoverPerTick;
    },
    target: $energy,
  });
  sample({
    source: {
      balanceCoins: $balanceCoins,
      profitPerTick: $$profitPerTick,
      turboBoostCoef: $turboBoostCoef,
    },
    clock: tick,
    // filter: ({turboBoostCoef}) => turboBoostCoef === null,
    fn: ({ balanceCoins, profitPerTick }) => {
      return balanceCoins + profitPerTick;
    },
    target: $balanceCoins,
  });
};
