import {
  createEvent,
  createEffect,
  sample,
  createStore,
  combine,
} from "effector";
import {
  $clickerState,
  $token,
  lastSyncFx,
  setMovesParticipationsCount,
} from "./auth";
import {
  ActiveEvent,
  CityBuilderResponnse,
  CityBuilderState,
  CityBuilding,
  ClickerState,
} from "./types";
import { Application } from "@splinetool/runtime";
// import envConfig from "../api/config";
import restClient from "../api/rest-client";
import { Move, MoveScope, MovesIndexResponse } from "./moves/moves.interfaces";

export const fetchCityBuilder = createEvent();
export const buyUpgradeProperty = createEvent<{
  name: string;
  level: number;
  upgrade_price: number;
}>();
export const openBuilding = createEvent<CityBuilding>();
export const closeBuilding = createEvent();
export const saveIndex = createEvent<number>();
export const saveIndex2 = createEvent<number>();
export const toggleBuyPopup = createEvent();
export const toggleCityTaskPopup = createEvent();
export const toggleCityEventPopup = createEvent();
export const toggleBuildingAnimate = createEvent();
export const checkIfTaskIsCompleted = createEvent<number>();
export const closeTaskRewardPopup = createEvent();
export const setCity = createEvent<Application>();
export const setShowFirstUpgradeAnimation = createEvent<boolean>();
export const getCurrentMove = createEvent();
export const clearCurrentMove = createEvent();

export const $showFirstUpdateAnimation = createStore(false);
export const $builderState = createStore<CityBuilderState | null>(null);
export const $openedBuilding = createStore<CityBuilding | null>(null);
export const $savedIndex = createStore<number | null>(null);
export const $savedIndex2 = createStore<number | null>(null);
export const $splineUrl = createStore<string | null>(null);
export const $cameraReset = createStore<{ id: string; event: string } | null>(
  null,
);

export const $currentMove = createStore<Move | null>(null);
export const $splineCityRef = createStore<Application | null>(null);
export const $isBuyPopupOpened = createStore(false);
export const $isCityTaskPopupOpened = createStore(false);
export const $isCityEventPopupOpened = createStore(false);
export const $isCityBuildAnimated = createStore(false);
export const $activeEvent = createStore<ActiveEvent | null>(null);
export const $isCityTaskCompletedReward = createStore(0);
export const $affectedByTaskBulding = combine(
  $builderState,
  $clickerState,
  (cityBuilderState, clickerState) => {
    if (cityBuilderState === null || clickerState === null) return undefined;
    const cityTask = clickerState.cityTask;
    const building = cityBuilderState.find(
      (building) => building.name === cityTask.buildingName,
    );
    return building;
  },
);
export const $affectedByTaskBuldingIndex = combine(
  $builderState,
  $clickerState,
  (cityBuilderState, clickerState) => {
    if (cityBuilderState === null || clickerState === null) return null;
    const cityTask = clickerState.cityTask;
    return cityBuilderState.findIndex(
      (building) => building.name === cityTask.buildingName,
    );
  },
);
export const $affectedByEventBuildings = combine(
  $builderState,
  $activeEvent,
  (buildings, activeEvent) => {
    if (buildings === null || activeEvent === null) return [];
    return buildings.filter(
      (building) =>
        (activeEvent.effects[0].building &&
          building.name === activeEvent.effects[0].building) ||
        (activeEvent.effects[0].category &&
          building.category === activeEvent.effects[0].category),
    );
  },
);
export const $affectedByEventBuildingsFirstIndex = combine(
  $builderState,
  $activeEvent,
  (buildings, activeEvent) => {
    if (buildings === null || activeEvent === null) return 0;
    const firstEffect = activeEvent.effects[0];
    const effectKeyToUse = firstEffect.building ? "building" : "category";
    const buildingKeyToUse =
      effectKeyToUse === "building" ? "name" : "category";

    const itemIndex = buildings.findIndex(
      (building) => building[buildingKeyToUse] === firstEffect[effectKeyToUse],
    );

    return itemIndex;
  },
);
$isCityTaskCompletedReward.reset(closeTaskRewardPopup);
$splineCityRef.on(setCity, (_, payload) => payload);
export const buyCityBuilderUpgradeFx = createEffect<
  { name: string; level: number; token: string },
  {
    clickerState: ClickerState;
    cityState: { buildings: CityBuilderState; activeEvent: ActiveEvent };
  }
>(async ({ name, level }) => {
  const result = await restClient.post(
    "clicker/city-buy-upgrade",
    JSON.stringify({ name, level }),
  );
  return result;
});
export const fetchCityStateFx = createEffect<string, CityBuilderResponnse>(
  async () => {
    const result = await restClient.post("clicker/city-state");
    return result;
  },
);

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
const fetchMoveFx = createEffect<void, Move[]>(async () => {
  const response: MovesIndexResponse = await restClient.post(
    "moves/index",
    JSON.stringify({
      scopes: [MoveScope.Active],
      limit: 1,
      offset: 0,
    }),
  );
  return response.moves;
});

sample({
  clock: getCurrentMove,
  target: fetchMoveFx,
});

sample({
  clock: clearCurrentMove,
  target: $currentMove.reinit,
});

sample({
  clock: fetchMoveFx.doneData,
  fn: (moves) => moves[0],
  target: $currentMove,
});

sample({
  source: $token,
  clock: fetchCityBuilder,
  filter: fetchCityStateFx.pending.map((status) => status === false),
  target: fetchCityStateFx,
});
sample({
  clock: checkIfTaskIsCompleted,
  target: $isCityTaskCompletedReward,
});

sample({
  clock: [fetchCityStateFx.doneData, buyCityBuilderUpgradeFx.doneData],
  fn: ({ cityState }) => cityState.buildings,
  target: $builderState,
});
sample({
  clock: [fetchCityStateFx.doneData, buyCityBuilderUpgradeFx.doneData],
  fn: ({ cityState }) => cityState.activeEvent,
  target: $activeEvent,
});
sample({
  source: $splineUrl,
  clock: lastSyncFx.doneData,
  filter: (splineUrl) => !splineUrl,
  fn: (_, { splineUrl }) => splineUrl,
  target: $splineUrl,
});
sample({
  clock: fetchCityStateFx.doneData,
  fn: ({ cameraReset }) => cameraReset,
  target: $cameraReset,
});

sample({
  clock: fetchCityStateFx.doneData,
  fn: ({ cityState }) => cityState.movesParticipationsCount,
  target: setMovesParticipationsCount,
});

sample({
  source: $token,
  clock: buyUpgradeProperty,
  fn: (token, { name, level }) => ({ name, level, token }),
  target: buyCityBuilderUpgradeFx,
});
sample({
  clock: openBuilding,
  target: $openedBuilding,
});
sample({
  clock: closeBuilding,
  target: $openedBuilding.reinit,
});

sample({
  clock: setShowFirstUpgradeAnimation,
  target: $showFirstUpdateAnimation,
});

sample({
  clock: saveIndex,
  target: $savedIndex,
});

sample({
  clock: saveIndex2,
  target: $savedIndex2,
});

sample({
  source: $isBuyPopupOpened,
  clock: toggleBuyPopup,
  filter: (isBuyPopupOpened) => isBuyPopupOpened === true,
  target: $isCityBuildAnimated.reinit,
});
sample({
  source: $isBuyPopupOpened,
  clock: toggleBuyPopup,
  fn: (prev) => !prev,
  target: $isBuyPopupOpened,
});

sample({
  source: $isCityTaskPopupOpened,
  clock: toggleCityTaskPopup,
  fn: (prev) => !prev,
  target: $isCityTaskPopupOpened,
});
sample({
  source: $isCityEventPopupOpened,
  clock: toggleCityEventPopup,
  fn: (prev) => !prev,
  target: $isCityEventPopupOpened,
});
sample({
  source: $isCityBuildAnimated,
  clock: toggleBuildingAnimate,
  fn: (prev) => !prev,
  target: $isCityBuildAnimated,
});

sample({
  source: $openedBuilding,
  clock: buyCityBuilderUpgradeFx.doneData,
  filter: (openedBuilding) => openedBuilding !== null,
  fn: (openedBuilding, { cityState }) => {
    const upgradedOpenedBuilding = cityState.buildings.find(
      (building) =>
        openedBuilding !== null && building.name === openedBuilding.name,
    );
    return upgradedOpenedBuilding ? upgradedOpenedBuilding : openedBuilding;
  },
  target: $openedBuilding,
});

sample({
  clock: buyCityBuilderUpgradeFx.done,
  filter: ({ params }) => {
    if (params.level === 1) return true;
    return false;
  },
  fn: () => false,
  target: $isCityBuildAnimated,
});
