import ReactGA from "react-ga4";
import envConfig from "../api/config";
import { Actions, Categories } from "./constants";

export const initializeGA = () => {
  if (envConfig.googleAnalyticsId) {
    ReactGA.initialize(envConfig.googleAnalyticsId);
  }
};

export const sendGAEvent = (
  category: Categories,
  action: Actions,
  label?: string,
) => {
  if (envConfig.googleAnalyticsId) {
    ReactGA.event({
      category: category,
      action: action,
      label,
    });
  }
};
