import { createBrowserRouter } from "react-router-dom";
import { routes } from "./routes.ts";
import { ErrorBoundary } from "../components/errors/ErrorBoundary.tsx";

export const router = createBrowserRouter([
  {
    element: <ErrorBoundary />,
    children: routes,
  },
]);
