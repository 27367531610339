import { sample } from "effector";
import { $clickerState, syncFx, lastSyncFx } from "./auth";
import { tapFx, turboTapFx } from "./clicker";
import { buyBoostFx } from "./boosts";
import { claimDailyRewardFx } from "./rewards";
import { fetchFriendsFx } from "./friends";
import { buyCityBuilderUpgradeFx } from "./citybuilder";
import { setCharacterFx } from "./character";
import { checkTaskFx } from "./tasks";

export const initSyncData = () => {
  Telegram.WebApp.isExpanded;
  sample({
    clock: [
      syncFx.doneData.map(({ clickerState }) => clickerState),
      tapFx.doneData,
      turboTapFx.doneData.map(({ clickerState }) => clickerState),
      buyBoostFx.doneData.map(({ clickerState }) => clickerState),
      claimDailyRewardFx.doneData,
      fetchFriendsFx.doneData.map(({ clickerState }) => clickerState),
      buyCityBuilderUpgradeFx.doneData.map(({ clickerState }) => clickerState),
      setCharacterFx.doneData.map(({ clickerState }) => clickerState),
      checkTaskFx.doneData.map(({ clickerState }) => clickerState),
      lastSyncFx.doneData.map(({ clickerState }) => clickerState),
    ],
    target: $clickerState,
  });
};
