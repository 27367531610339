import { createEvent, createStore, sample } from "effector";

export const $isNextLeaguePopupOpened = createStore(false);

export const toggleNextLeaguePopup = createEvent();
export const showNextLeaguePopup = createEvent();

export const setRankLevelOnLeagueModalOpen = createEvent<number>();

$isNextLeaguePopupOpened.on(showNextLeaguePopup, () => true);

sample({
  source: $isNextLeaguePopupOpened,
  clock: toggleNextLeaguePopup,
  fn: (prev) => !prev,
  target: $isNextLeaguePopupOpened,
});
