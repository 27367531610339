const apiUrl = import.meta.env.VITE_API_URL;
const envirtoment = import.meta.env.MODE;
const googleAnalyticsId = import.meta.env.VITE_GA_TRACKING_ID;
const envConfig = {
  apiUrl,
  envirtoment,
  googleAnalyticsId,
};

export default envConfig;
