/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { setInvoiceStatus } from "./state/stars.ts";
import { router } from "./router/router.tsx";
import { Layout } from "./Layout";
import { pageLoaded } from "./state/state.ts";
import { initI18n } from "./utils/i18n.ts";
import {
  $listenersAdded,
  $token,
  addListeners,
  authFx,
  callLastSyncFx,
  handleBeacon,
} from "./state/auth.ts";
import { initTickEvents } from "./state/events/initTickEvents.ts";
import { initSyncData } from "./state/initSyncData.ts";
import { initializeGA } from "./analytics/index.ts";

import landscape_lock from "./assets/landscape_lock.png";

import "./index.css";
import { initCentry } from "./centry/index.ts";
import { useUnit } from "effector-react";
import telegramApiController from "./module/telegram-api-controller/index.ts";

export const allowedPlatform = ["android", "ios"];

const initApp = async () => {
  await initI18n();
  await authFx();
  pageLoaded();

  initCentry();
};

initApp();
// eslint-disable-next-line
const App = () => {
  const [isPortrait, setIsPortrait] = useState(
    window.innerHeight > window.innerWidth,
  );

  const [token, listenersAdded] = useUnit([$token, $listenersAdded]);

  useEffect(() => {
    if (token) {
      initSyncData();
      initTickEvents();
      initializeGA();
    }
  }, [token]);

  const handleResize = () => {
    setIsPortrait(window.innerHeight > window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleSetInvoice = (data: { url: string; status: string }) => {
      setInvoiceStatus(data);
    };
    if (telegramApiController.isInvoiceSupported) {
      Telegram.WebApp.onEvent("invoiceClosed", handleSetInvoice);
    }
    return () => {
      if (telegramApiController.isInvoiceSupported) {
        Telegram.WebApp.offEvent("invoiceClosed", handleSetInvoice);
      }
    };
  }, []);

  console.log(telegramApiController.isInvoiceSupported, "<-- supported");

  useEffect(() => {
    if (!token) return;

    if (!listenersAdded) {
      let visibilityChange = "";

      if (typeof document.hidden !== "undefined") {
        visibilityChange = "visibilitychange";
        // @ts-ignore
      } else if (typeof document.msHidden !== "undefined") {
        visibilityChange = "msvisibilitychange";
        // @ts-ignore
      } else if (typeof document.webkitHidden !== "undefined") {
        visibilityChange = "webkitvisibilitychange";
      }
      const sendBeacon = async () => {
        if (token) {
          handleBeacon();
        }
      };

      const handleVisibilityChange = () => {
        let hidden = "";

        // @ts-ignore
        if (typeof document.hidden !== "undefined") {
          hidden = "hidden";
          // @ts-ignore
        } else if (typeof document.msHidden !== "undefined") {
          hidden = "msHidden";
          // @ts-ignore
        } else if (typeof document.webkitHidden !== "undefined") {
          hidden = "webkitHidden";
        }
        // @ts-ignore
        if (document[hidden]) {
          sendBeacon();
        } else {
          callLastSyncFx();
        }
      };

      window.addEventListener(visibilityChange, handleVisibilityChange);
      // window.addEventListener("pagehide", sendBeacon, false);
      window.addEventListener("pageshow", () => {
        callLastSyncFx();
      });
      window.addEventListener("load", () => {
        callLastSyncFx();
      });
      addListeners();
    }
  }, [token, listenersAdded]);

  if (!isPortrait) {
    return (
      <div
        className="absolute inset-0 flex h-screen w-full items-start justify-center bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${landscape_lock})`,
        }}
      >
        <h1 className="pt-8 text-center text-[5rem] font-bold text-white">
          retourne-le
        </h1>
      </div>
    );
  }

  return (
    <Layout>
      <RouterProvider router={router} />
    </Layout>
  );
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
