import { createEffect, createEvent, createStore, sample } from "effector";
import { createGate } from "effector-react";
import { not } from "patronum";

import { $clickerState, $token } from "./auth";
import { RankingData, FriendsData } from "./types";
// import envConfig from "../api/config";
import restClient from "../api/rest-client";
export const FriendsGate = createGate();

export const $friendsRewardPopupOpened = createStore(false);
export const changeLeague = createEvent<number>();
export const fetchFriends = createEvent();
export const refreshFriends = createEvent();
export const setFriendsRewardPopupOpened = createEvent<boolean>();
export const decreaseCooldown = createEvent<number>();

export const $rankInfo = createStore<RankingData | null>(null);
export const $friendsInfo = createStore<FriendsData | null>(null);
export const $friendsRefreshCooldown = createStore(0).on(
  decreaseCooldown,
  (cooldown, passed) => (cooldown - passed < 0 ? 0 : cooldown - passed),
);

sample({
  clock: setFriendsRewardPopupOpened,
  target: $friendsRewardPopupOpened,
});

export const fetchChampsFx = createEffect<
  { token: string; rankLevel: number },
  RankingData
>(async ({ rankLevel }) => {
  const result = await restClient.post(
    "clicker/rank",
    JSON.stringify({ rankLevel }),
  );
  return result;
});

export const fetchFriendsFx = createEffect<
  { token: string; type?: "refresh" },
  FriendsData
>(async () => {
  const result = await restClient.post("clicker/friends");
  return result;
});

sample({
  source: {
    token: $token,
    clickerState: $clickerState,
  },
  clock: [changeLeague],
  fn: ({ token }, rankLevel) => {
    return { rankLevel, token };
  },
  target: fetchChampsFx,
});

sample({
  source: {
    friendsRewardPopupOpened: $friendsRewardPopupOpened,
    isRequestPending: fetchFriendsFx.pending,
  },
  clock: FriendsGate.open,
  filter: ({ friendsRewardPopupOpened, isRequestPending }) =>
    !friendsRewardPopupOpened && !isRequestPending,
  target: fetchFriends,
});
sample({
  source: $token,
  clock: fetchFriends,
  fn: (token) => ({ token }),
  target: fetchFriendsFx,
});
sample({
  source: $token,
  clock: refreshFriends,
  filter: not(fetchFriendsFx.pending),
  fn: (token) => ({ token, type: "refresh" as const }),
  target: fetchFriendsFx,
});
sample({
  clock: fetchFriendsFx,
  filter: ({ type }) => type === "refresh",
  fn: () => 60000,
  target: $friendsRefreshCooldown,
});
sample({
  clock: fetchChampsFx.doneData,
  target: $rankInfo,
});

sample({
  clock: fetchFriendsFx.doneData,
  target: $friendsInfo,
}).watch((data) => {
  if (data && data.earned && data.earned > 0) {
    setFriendsRewardPopupOpened(true);
  }
});
