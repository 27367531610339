import { createEffect, createEvent, sample } from "effector";
import { ClickerState } from "./types";
import { $token } from "./auth";

import restClient from "../api/rest-client";

export const claimDailyReward = createEvent();
export const claimDailyRewardFx = createEffect<string, ClickerState>(
  async () => {
    // const response = await fetch(
    //   `${envConfig.apiUrl}/clicker/claim-daily-reward`,
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   },
    // );
    // const result = await response.json();
    const result = await restClient.post(
      "clicker/claim-daily-reward",
      JSON.stringify({}),
    );
    return result.clickerState;
  },
);

sample({
  source: $token,
  clock: claimDailyReward,
  target: claimDailyRewardFx,
});
