// moves index payload
export enum MoveScope {
  All = "all",
  My = "my",
  Finished = "finished",
  NotFinished = "not_finished",
  Active = "active",
  Upcoming = "upcoming",
  Claimable = "claimable",
  Created = "created",
}

export interface MovesIndexPayload {
  scopes: MoveScope[];
  offset: number;
  limit: number;
  locale: string;
}

// moves index response
export interface UserParticipation {
  id: number;
  amount: number;
  status: string;
  vote: string;
  createdAt: number;
  isCancellable: boolean;
  isClaimable: boolean;
  wonAmount: number;
}

export interface Votes {
  participatedCoins: number;
  participatedUsers: number;
}

export interface Move {
  id: string;
  title: string;
  description: string;
  endsAt: number;
  startsAt: number;
  status: string;
  totalParticipatedCoins: number;
  totalParticipatedUsers: number;
  openForParticipation: boolean;
  imageUrl: string;
  userParticipations: UserParticipation[];
  lotteryTicketsCount: number;
  lotteryTicketsType?: "daily_free" | "daily_paid" | "weekly_paid";
  votes: {
    no: Votes;
    yes: Votes;
  };
}

export interface MovesIndexResponse {
  moves: Move[];
  notifications: {
    count: number;
  };
}

// move payload
export interface MovePayload {
  id: string;
}

// move response
export interface MoveResponse {
  move: Move;
}

// move bet action payload
export interface MoveBetActionPayload {
  id: string;
  amount: number;
  vote: "yes" | "no";
}
