import { createEffect, createEvent, createStore, sample } from "effector";
import { Boost, ClickerState } from "./types";

import { $token } from "./auth";
import { turboTapFx } from "./clicker";
import { TURBO_BOOST_TIMER } from "./constants";

import { tick } from "./state";
import restClient from "../api/rest-client";

// Создаем хранилища
export const $boosters = createStore<Boost[]>([]);
export const $freeBoosters = $boosters.map((boosters) =>
  boosters.filter((booster) => booster.price === 0),
);
export const $premiumBoosters = $boosters.map((boosters) =>
  boosters.filter((booster) => booster.price > 0),
);

export const $boosterTimeLeft = createStore(0);
export const $isTurboBoostEnabled = createStore(false);
export const $isTurboBoostActivated = createStore(false);
export const $turboBoostCoef = createStore<number | null>(null);
export const $previousTurboBoost = createStore<{
  turboEarned: number;
  tapCount: number;
} | null>(null);

// Создаем события
export const fetchBoosts = createEvent();
export const closePreviousBoostPopup = createEvent();
export const buyBoost = createEvent<string>();
export const startTurboTimer = createEvent();
export const finishTurboTimer = createEvent();
export const setTurboBoostEnabled = createEvent();

// Создаем эффекты
export const fetchBoostersFx = createEffect<string, Boost[]>(async () => {
  // const response = await fetch(`${envConfig.apiUrl}/clicker/boosts-for-buy`, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${token}`,
  //   },
  // });
  const result = await restClient.post("clicker/boosts-for-buy");
  return result.boostsForBuy;
});

export const buyBoostFx = createEffect<
  { boostId: string; token: string },
  { boostsForBuy: Boost[]; clickerState: ClickerState }
>(async ({ boostId }) => {
  const requestDate = new Date();
  const timestamp = Math.floor(Date.now() / 1000);
  // const response = await fetch(`${envConfig.apiUrl}/clicker/buy-boost`, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${token}`,
  //   },
  //   body: JSON.stringify({
  //     boostId,
  //     timestamp,
  //   }),
  // });
  const { boostsForBuy, clickerState } = await restClient.post(
    "clicker/buy-boost",
    JSON.stringify({ boostId, timestamp }),
  );
  return {
    boostsForBuy,
    clickerState: {
      requestDate,
      ...clickerState,
    },
  };
});

// export const startTimerFx = createEffect<
//   { turboBoostCoef: number; isTurboBoostEnabled: boolean },
//   undefined
// >(() => new Promise((rs) => setTimeout(rs, TURBO_BOOST_TIMER)));

export const calculateCoefFx = createEffect(() => {
  const multipliers = [2, 3, 4, 5, 6, 7, 8, 9, 10];
  const probabilities = [0.4, 0.2, 0.12, 0.08, 0.07, 0.05, 0.04, 0.02, 0.02];

  // Generate a random number between 0 and 1
  const random = Math.random();

  // Calculate cumulative probabilities
  let cumulativeProbability = 0;
  for (let i = 0; i < multipliers.length; i++) {
    cumulativeProbability += probabilities[i];
    if (random < cumulativeProbability) {
      return multipliers[i];
    }
  }
  return multipliers[multipliers.length - 1];
});

// Семплы
sample({
  source: {
    boosterTimeLeft: $boosterTimeLeft,
    isTurboBoostEnabled: $isTurboBoostEnabled,
  },
  clock: tick,
  filter: ({ boosterTimeLeft, isTurboBoostEnabled }) =>
    isTurboBoostEnabled && boosterTimeLeft > 0,
  fn: ({ boosterTimeLeft }) => boosterTimeLeft - 1,
  target: $boosterTimeLeft,
}).watch((timeLeft) => {
  if (timeLeft === 0) {
    finishTurboTimer();
  }
});

sample({
  source: {
    boosterTimeLeft: $boosterTimeLeft,
    isTurboBoostEnabled: $isTurboBoostEnabled,
  },
  clock: startTurboTimer,
  filter: ({ boosterTimeLeft, isTurboBoostEnabled }) =>
    isTurboBoostEnabled && boosterTimeLeft === 0,
  fn: () => TURBO_BOOST_TIMER / 1000,
  target: $boosterTimeLeft,
});

sample({
  source: {
    token: $token,
    isBoostLoading: fetchBoostersFx.pending,
  },

  clock: fetchBoosts,
  filter: ({ isBoostLoading }) => !isBoostLoading,
  fn: ({ token }) => token,
  target: fetchBoostersFx,
});
sample({
  clock: [
    fetchBoostersFx.doneData,
    buyBoostFx.doneData.map(({ boostsForBuy }) => boostsForBuy),
  ],
  target: $boosters,
});

sample({
  source: $token,
  clock: buyBoost,
  fn: (token, boostId) => ({ boostId, token }),
  target: buyBoostFx,
});

sample({
  clock: buyBoostFx.done,
  filter: ({ params }) => params.boostId === "BoostTurbo",
  fn: () => true,
  target: $isTurboBoostEnabled,
});

sample({
  clock: setTurboBoostEnabled,
  fn: () => true,
  target: $isTurboBoostEnabled,
});

sample({
  clock: calculateCoefFx.doneData,
  target: $turboBoostCoef,
});

sample({
  clock: startTurboTimer,
  fn: () => true,
  target: $isTurboBoostActivated,
});

sample({
  clock: turboTapFx.done,
  fn: () => false,
  target: [
    $isTurboBoostActivated.reinit,
    $isTurboBoostEnabled.reinit,
    $turboBoostCoef.reinit,
  ],
});

sample({
  clock: finishTurboTimer,
  fn: () => 0,
  target: $boosterTimeLeft,
});

sample({
  clock: turboTapFx.doneData,
  fn: ({ previousTurboTap }) => previousTurboTap,
  target: $previousTurboBoost,
});
sample({
  clock: closePreviousBoostPopup,
  target: $previousTurboBoost.reinit,
});
