export enum TelegramApiFeatures {
  VericalSwipeControl = "verticalSwipeControl",
  OpenInvoice = "openInvoice",
}

export const botApiVersions = [
  "7.10",
  "7.9",
  "7.8",
  "7.7",
  "7.6",
  "7.5",
  "7.4",
  "7.3",
  "7.2",
  "7.1",
  "7.0",
  "6.9",
  "6.8",
  "6.7",
  "6.6",
  "6.5",
  "6.4",
  "6.3",
  "6.2",
  "6.1",
  "6.0",
];

export const TelegramApiFeaturesVersionsMap: Record<
  TelegramApiFeatures,
  string
> = {
  [TelegramApiFeatures.VericalSwipeControl]: "7.7",
  [TelegramApiFeatures.OpenInvoice]: "6.1",
};

export enum InvoiceStatus {
  Empty = "empty",
  Paid = "paid", // invoice was paid successfully
  Cancelled = "cancelled", // user closed this invoice without paying
  Failed = "failed", // user tried to pay, but the payment was failed
  Pending = "pending", // the payment is still processing. The bot will receive a service message
}
