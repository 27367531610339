import { TFunction } from "i18next";

import bronze from "../assets/leagues/bronze_league.webp";
import silver from "../assets/leagues/silver_league.webp";
import gold from "../assets/leagues/gold_league.webp";
import platinum from "../assets/leagues/platinum_league.webp";
import titanium from "../assets/leagues/titanium_league.webp";
import sapphire from "../assets/leagues/sapphire_league.webp";
import emerald from "../assets/leagues/emerald_league.webp";
import ruby from "../assets/leagues/ruby_league.webp";
import diamond from "../assets/leagues/diamond_league.webp";
import quantumNexus from "../assets/leagues/quantum_nexus_league.webp";
import alpha from "../assets/leagues/alpha_league.webp";
import sigma from "../assets/leagues/sigma_league.webp";

import bank_small from "../assets/buildings/bank_small.png";
import bank_locked from "../assets/buildings/bank_locked.png";
import bank_filled from "../assets/buildings/bank_filled.png";
import bank_1 from "../assets/buildings/bank_1.webp";
import bank_2 from "../assets/buildings/bank_2.webp";
import bank_3 from "../assets/buildings/bank_3.webp";
import bank_4 from "../assets/buildings/bank_4.webp";
import bank_5 from "../assets/buildings/bank_5.webp";

import binary_market_small from "../assets/buildings/binary_market_small.png";
import binary_market_locked from "../assets/buildings/binary_market_locked.png";
import binary_market_filled from "../assets/buildings/binary_market_filled.png";
import binary_market_1 from "../assets/buildings/binary_market_1.webp";
import binary_market_2 from "../assets/buildings/binary_market_2.webp";
import binary_market_3 from "../assets/buildings/binary_market_3.webp";
import binary_market_4 from "../assets/buildings/binary_market_4.webp";
import binary_market_5 from "../assets/buildings/binary_market_5.webp";

import crypto_church_small from "../assets/buildings/crypto_church_small.png";
import crypto_church_locked from "../assets/buildings/crypto_church_locked.png";
import crypto_church_filled from "../assets/buildings/crypto_church_filled.png";
import crypto_church_1 from "../assets/buildings/crypto_church_1.webp";
import crypto_church_2 from "../assets/buildings/crypto_church_2.webp";
import crypto_church_3 from "../assets/buildings/crypto_church_3.webp";
import crypto_church_4 from "../assets/buildings/crypto_church_4.webp";
import crypto_church_5 from "../assets/buildings/crypto_church_5.webp";

import crypto_farm_small from "../assets/buildings/crypto_farm_small.png";
import crypto_farm_locked from "../assets/buildings/crypto_farm_locked.png";
import crypto_farm_filled from "../assets/buildings/crypto_farm_filled.png";
import crypto_farm_1 from "../assets/buildings/crypto_farm_1.webp";
import crypto_farm_2 from "../assets/buildings/crypto_farm_2.webp";
import crypto_farm_3 from "../assets/buildings/crypto_farm_3.webp";
import crypto_farm_4 from "../assets/buildings/crypto_farm_4.webp";
import crypto_farm_5 from "../assets/buildings/crypto_farm_5.webp";

import factory_small from "../assets/buildings/factory_small.png";
import factory_locked from "../assets/buildings/factory_locked.png";
import factory_filled from "../assets/buildings/factory_filled.png";
import factory_1 from "../assets/buildings/factory_1.webp";
import factory_2 from "../assets/buildings/factory_2.webp";
import factory_3 from "../assets/buildings/factory_3.webp";
import factory_4 from "../assets/buildings/factory_4.webp";
import factory_5 from "../assets/buildings/factory_5.webp";

import town_hall_small from "../assets/buildings/town_hall_small.png";
import town_hall_locked from "../assets/buildings/town_hall_locked.png";
import town_hall_filled from "../assets/buildings/town_hall_filled.png";
import town_hall_1 from "../assets/buildings/town_hall_1.webp";
import town_hall_2 from "../assets/buildings/town_hall_2.webp";
import town_hall_3 from "../assets/buildings/town_hall_3.webp";
import town_hall_4 from "../assets/buildings/town_hall_4.webp";
import town_hall_5 from "../assets/buildings/town_hall_5.webp";

import trading_center_small from "../assets/buildings/trading_center_small.png";
import trading_center_locked from "../assets/buildings/trading_center_locked.png";
import trading_center_filled from "../assets/buildings/trading_center_filled.png";
import trading_center_1 from "../assets/buildings/trading_center_1.webp";
import trading_center_2 from "../assets/buildings/trading_center_2.webp";
import trading_center_3 from "../assets/buildings/trading_center_3.webp";
import trading_center_4 from "../assets/buildings/trading_center_4.webp";
import trading_center_5 from "../assets/buildings/trading_center_5.webp";

import it_training_center_small from "../assets/buildings/it_training_center_small.png";
import it_training_center_locked from "../assets/buildings/it_training_center_locked.png";
import it_training_center_filled from "../assets/buildings/it_training_center_filled.png";
import it_training_center_1 from "../assets/buildings/it_training_center_1.webp";
import it_training_center_2 from "../assets/buildings/it_training_center_2.webp";
import it_training_center_3 from "../assets/buildings/it_training_center_3.webp";
import it_training_center_4 from "../assets/buildings/it_training_center_4.webp";
import it_training_center_5 from "../assets/buildings/it_training_center_5.webp";

import digital_mall_small from "../assets/buildings/digital_mall_small.png";
import digital_mall_locked from "../assets/buildings/digital_mall_locked.png";
import digital_mall_filled from "../assets/buildings/digital_mall_filled.png";
import digital_mall_1 from "../assets/buildings/digital_mall_1.webp";
import digital_mall_2 from "../assets/buildings/digital_mall_2.webp";
import digital_mall_3 from "../assets/buildings/digital_mall_3.webp";
import digital_mall_4 from "../assets/buildings/digital_mall_4.webp";
import digital_mall_5 from "../assets/buildings/digital_mall_5.webp";

import hackerspace_small from "../assets/buildings/hackerspace_small.png";
import hackerspace_locked from "../assets/buildings/hackerspace_locked.png";
import hackerspace_filled from "../assets/buildings/hackerspace_filled.png";
import hackerspace_1 from "../assets/buildings/hackerspace_1.webp";
import hackerspace_2 from "../assets/buildings/hackerspace_2.webp";
import hackerspace_3 from "../assets/buildings/hackerspace_3.webp";
import hackerspace_4 from "../assets/buildings/hackerspace_4.webp";
import hackerspace_5 from "../assets/buildings/hackerspace_5.webp";

import store_24h_small from "../assets/buildings/store_24h_small.webp";
import store_24h_locked from "../assets/buildings/store_24h_locked.webp";
import store_24h_filled from "../assets/buildings/store_24h_filled.webp";
import store_24h_1 from "../assets/buildings/store_24h_1.webp";

import eatery_small from "../assets/buildings/eatery_small.webp";
import eatery_locked from "../assets/buildings/eatery_locked.webp";
import eatery_filled from "../assets/buildings/eatery_filled.webp";
import eatery_1 from "../assets/buildings/eatery_1.webp";

import dormitory_small from "../assets/buildings/dormitory_small.webp";
import dormitory_locked from "../assets/buildings/dormitory_locked.webp";
import dormitory_filled from "../assets/buildings/dormitory_filled.webp";
import dormitory_1 from "../assets/buildings/dormitory_1.webp";

import transformer_station_small from "../assets/buildings/transofrmer_station_small.webp";
import transformer_station_locked from "../assets/buildings/transformer_station_locked.webp";
import transformer_station_filled from "../assets/buildings/transformer_station_filled.webp";
import transformer_station_1 from "../assets/buildings/transformer_station_1.webp";

import robo_advising_center_small from "../assets/buildings/robo_advising_center_small.png";
import robo_advising_center_locked from "../assets/buildings/robo_advising_center_locked.png";
import robo_advising_center_filled from "../assets/buildings/robo_advising_center_filled.png";
import robo_advising_center_1 from "../assets/buildings/robo_advising_center_1.webp";
import robo_advising_center_2 from "../assets/buildings/robo_advising_center_2.webp";
import robo_advising_center_3 from "../assets/buildings/robo_advising_center_3.webp";
import robo_advising_center_4 from "../assets/buildings/robo_advising_center_4.webp";
import robo_advising_center_5 from "../assets/buildings/robo_advising_center_5.webp";

import wellness_center_small from "../assets/buildings/wellness_center_small.png";
import wellness_center_locked from "../assets/buildings/wellness_center_locked.png";
import wellness_center_filled from "../assets/buildings/wellness_center_filled.png";
import wellness_center_1 from "../assets/buildings/wellness_center_1.webp";
import wellness_center_2 from "../assets/buildings/wellness_center_2.webp";
import wellness_center_3 from "../assets/buildings/wellness_center_3.webp";
import wellness_center_4 from "../assets/buildings/wellness_center_4.webp";
import wellness_center_5 from "../assets/buildings/wellness_center_5.webp";

import monument_small from "../assets/buildings/monument_small.webp";
import monument_locked from "../assets/buildings/monument_locked.webp";
import monument_filled from "../assets/buildings/monument_filled.webp";
import monument_1 from "../assets/buildings/monument_1.webp";

import cyber_defense_center_small from "../assets/buildings/cyber_defense_center_small.webp";
import cyber_defense_center_locked from "../assets/buildings/cyber_defense_center_locked.webp";
import cyber_defense_center_filled from "../assets/buildings/cyber_defense_center_filled.webp";
import cyber_defense_center_1 from "../assets/buildings/cyber_defense_center_1.webp";

import leisure_park_small from "../assets/buildings/leisure_park_small.webp";
import leisure_park_locked from "../assets/buildings/leisure_park_locked.webp";
import leisure_park_filled from "../assets/buildings/leisure_park_filled.webp";
import leisure_park_1 from "../assets/buildings/leisure_park_1.webp";

import NFT_gallery_small from "../assets/buildings/NFT_gallery_small.webp";
import NFT_gallery_locked from "../assets/buildings/NFT_gallery_locked.webp";
import NFT_gallery_filled from "../assets/buildings/NFT_gallery_filled.webp";
import NFT_gallery_1 from "../assets/buildings/NFT_gallery_1.webp";

import tgIconSmall from "../assets/social/tg_icon_small.png";
import tgIconBig from "../assets/social/tg_icon_big.png";
import xIconSmall from "../assets/social/x_icon_small.png";
import xIconBig from "../assets/social/x_icon_big.png";
import youtubeIconSmall from "../assets/social/youtube_icon_small.png";
import youtubeIconBig from "../assets/social/youtube_icon_big.png";
import instagramIconSmall from "../assets/social/instagram_icon_small.png";
import instagramIconBig from "../assets/social/instagram_icon_big.png";
import fifteenFriendsIconSmall from "../assets/social/fifteen_friends_icon_small.png";
import fifteenFriendsIconBig from "../assets/social/fifteen_friends_icon_big.png";
import happyThreeFriendsIconSmall from "../assets/social/happy_three_friends_icon_small.png";
import happyThreeFriendsIconBig from "../assets/social/happy_three_friends_icon_big.png";
import facebookIcon from "../assets/social/fb.png";
import twitchIcon from "../assets/social/twitch.png";
import openUrlIcon from "../assets/social/link.png";

import zehphyrImg from "../assets/characters/Zephyr.png";
import tiko83Img from "../assets/characters/Tiko83.png";
import hopperImg from "../assets/characters/Hopper.png";
import hopperClicker from "../assets/characters/hopper_clicker.png";
import zephyrClicker from "../assets/characters/zephyr_clicker.png";
import tiko83Clicker from "../assets/characters/tiko83_clicker.png";
import zephyrIcon from "../assets/characters/zephyr_small.png";
import hopperIcon from "../assets/characters/hopper_small.png";
import tiko83Icon from "../assets/characters/tiko83_small.png";

import bearMarketSmall from "../pages/GamePage/CityBuilderPage/assets/event-covers/bear_market_small.webp";
import blockchainConferenceSmall from "../pages/GamePage/CityBuilderPage/assets/event-covers/blockchain_conference_small.webp";
import bullMarketSmall from "../pages/GamePage/CityBuilderPage/assets/event-covers/bull_market_small.webp";
import cyberDefenseDrillSmall from "../pages/GamePage/CityBuilderPage/assets/event-covers/cyber_drill_small.webp";
import hackerAttackSmall from "../pages/GamePage/CityBuilderPage/assets/event-covers/hacker_attack_small.webp";
import newCryptocurrencyLaunchSmall from "../pages/GamePage/CityBuilderPage/assets/event-covers/new_crypto_launch_small.webp";
import technologicalBreakthroughSmall from "../pages/GamePage/CityBuilderPage/assets/event-covers/techbreakthrough_small.webp";
import bearMarketHidden from "../pages/GamePage/CityBuilderPage/assets/event-covers/bear_market_hidden.webp";
import blockchainConferenceHidden from "../pages/GamePage/CityBuilderPage/assets/event-covers/blockchain_conference_hidden.webp";
import bullMarketHidden from "../pages/GamePage/CityBuilderPage/assets/event-covers/bull_market_hidden.webp";
import cyberDefenseDrillHidden from "../pages/GamePage/CityBuilderPage/assets/event-covers/cyber_drill_hidden.webp";
import hackerAttackHidden from "../pages/GamePage/CityBuilderPage/assets/event-covers/hacker_attack_hidden.webp";
import newCryptocurrencyLaunchHidden from "../pages/GamePage/CityBuilderPage/assets/event-covers/new_crypto_launch_hidden.webp";
import technologicalBreakthroughHidden from "../pages/GamePage/CityBuilderPage/assets/event-covers/techbreakthrough_hidden.webp";

export const LEAGUES = [
  {
    name: "Bronze",
  },
  {
    name: "Silver",
  },
  {
    name: "Gold",
  },
  {
    name: "Platinum",
  },
  {
    name: "Titanium",
  },
  {
    name: "Sapphire",
  },
  {
    name: "Emerald",
  },
  {
    name: "Ruby",
  },
  {
    name: "Diamond",
  },
  {
    name: "Quantum Nexus",
  },
  {
    name: "Alpha",
  },
  {
    name: "Sigma",
  },
];

export const leaguesMap: { [key: string]: string } = {
  Bronze: bronze,
  Silver: silver,
  Gold: gold,
  Platinum: platinum,
  Titanium: titanium,
  Sapphire: sapphire,
  Emerald: emerald,
  Ruby: ruby,
  Diamond: diamond,
  QuantumNexus: quantumNexus,
  Alpha: alpha,
  Sigma: sigma,
};

export const buildingsSmallMap: { [key: string]: string } = {
  bank: bank_small,
  binary_market: binary_market_small,
  crypto_church: crypto_church_small,
  crypto_farm: crypto_farm_small,
  factory: factory_small,
  it_training_center: it_training_center_small,
  town_hall: town_hall_small,
  trading_center: trading_center_small,
  digital_mall: digital_mall_small,
  hackerspace: hackerspace_small,
  robo_advising_center: robo_advising_center_small,
  wellness_center: wellness_center_small,
  store_24h: store_24h_small,
  eatery: eatery_small,
  dormitory: dormitory_small,
  transformer_station: transformer_station_small,
  monument: monument_small,
  cyber_defense_center: cyber_defense_center_small,
  leisure_park: leisure_park_small,
  nft_gallery: NFT_gallery_small,
};

export const buildingsLockedMap: { [key: string]: string } = {
  bank: bank_locked,
  binary_market: binary_market_locked,
  crypto_church: crypto_church_locked,
  crypto_farm: crypto_farm_locked,
  factory: factory_locked,
  it_training_center: it_training_center_locked,
  town_hall: town_hall_locked,
  trading_center: trading_center_locked,
  digital_mall: digital_mall_locked,
  hackerspace: hackerspace_locked,
  robo_advising_center: robo_advising_center_locked,
  wellness_center: wellness_center_locked,
  store_24h: store_24h_locked,
  eatery: eatery_locked,
  dormitory: dormitory_locked,
  transformer_station: transformer_station_locked,
  monument: monument_locked,
  cyber_defense_center: cyber_defense_center_locked,
  leisure_park: leisure_park_locked,
  nft_gallery: NFT_gallery_locked,
};

export const buildingsFilledMap: { [key: string]: string } = {
  bank: bank_filled,
  binary_market: binary_market_filled,
  crypto_church: crypto_church_filled,
  crypto_farm: crypto_farm_filled,
  factory: factory_filled,
  it_training_center: it_training_center_filled,
  town_hall: town_hall_filled,
  trading_center: trading_center_filled,
  digital_mall: digital_mall_filled,
  hackerspace: hackerspace_filled,
  robo_advising_center: robo_advising_center_filled,
  wellness_center: wellness_center_filled,
  store_24h: store_24h_filled,
  eatery: eatery_filled,
  dormitory: dormitory_filled,
  transformer_station: transformer_station_filled,
  monument: monument_filled,
  cyber_defense_center: cyber_defense_center_filled,
  leisure_park: leisure_park_filled,
  nft_gallery: NFT_gallery_filled,
};

export const getBuildingsNamesMap = (
  t: TFunction,
): Record<
  string,
  {
    title: string;
    description: string;
  }
> => ({
  bank: {
    title: t("buildings.bank.title"),
    description: t("buildings.bank.description"),
  },
  binary_market: {
    title: t("buildings.binary_market.title"),
    description: t("buildings.binary_market.description"),
  },
  crypto_church: {
    title: t("buildings.crypto_church.title"),
    description: t("buildings.crypto_church.description"),
  },
  crypto_farm: {
    title: t("buildings.crypto_farm.title"),
    description: t("buildings.crypto_farm.description"),
  },
  factory: {
    title: t("buildings.factory.title"),
    description: t("buildings.factory.description"),
  },
  it_training_center: {
    title: t("buildings.it_training_center.title"),
    description: t("buildings.it_training_center.description"),
  },
  town_hall: {
    title: t("buildings.town_hall.title"),
    description: t("buildings.town_hall.description"),
  },
  trading_center: {
    title: t("buildings.trading_center.title"),
    description: t("buildings.trading_center.description"),
  },
  digital_mall: {
    title: t("buildings.digital_mall.title"),
    description: t("buildings.digital_mall.description"),
  },
  hackerspace: {
    title: t("buildings.hackerspace.title"),
    description: t("buildings.hackerspace.description"),
  },
  robo_advising_center: {
    title: t("buildings.robo_advising_center.title"),
    description: t("buildings.robo_advising_center.description"),
  },
  wellness_center: {
    title: t("buildings.wellness_center.title"),
    description: t("buildings.wellness_center.description"),
  },
  transformer_station: {
    title: t("buildings.transformer_station.title"),
    description: t("buildings.transformer_station.description"),
  },
  store_24h: {
    title: t("buildings.store_24h.title"),
    description: t("buildings.store_24h.description"),
  },
  dormitory: {
    title: t("buildings.dormitory.title"),
    description: t("buildings.dormitory.description"),
  },
  eatery: {
    title: t("buildings.eatery.title"),
    description: t("buildings.eatery.description"),
  },
  monument: {
    title: t("buildings.monument.title"),
    description: t("buildings.monument.description"),
  },
  cyber_defense_center: {
    title: t("buildings.cyber_defense_center.title"),
    description: t("buildings.cyber_defense_center.description"),
  },
  leisure_park: {
    title: t("buildings.leisure_park.title"),
    description: t("buildings.leisure_park.description"),
  },
  nft_gallery: {
    title: t("buildings.nft_gallery.title"),
    description: t("buildings.nft_gallery.description"),
  },
});

export const buildingsCoversMap: { [key: string]: string } = {
  bank_1: bank_1,
  bank_2: bank_2,
  bank_3: bank_3,
  bank_4: bank_4,
  bank_5: bank_5,
  binary_market_1: binary_market_1,
  binary_market_2: binary_market_2,
  binary_market_3: binary_market_3,
  binary_market_4: binary_market_4,
  binary_market_5: binary_market_5,
  crypto_church_1: crypto_church_1,
  crypto_church_2: crypto_church_2,
  crypto_church_3: crypto_church_3,
  crypto_church_4: crypto_church_4,
  crypto_church_5: crypto_church_5,
  crypto_farm_1: crypto_farm_1,
  crypto_farm_2: crypto_farm_2,
  crypto_farm_3: crypto_farm_3,
  crypto_farm_4: crypto_farm_4,
  crypto_farm_5: crypto_farm_5,
  factory_1: factory_1,
  factory_2: factory_2,
  factory_3: factory_3,
  factory_4: factory_4,
  factory_5: factory_5,
  it_training_center_1: it_training_center_1,
  it_training_center_2: it_training_center_2,
  it_training_center_3: it_training_center_3,
  it_training_center_4: it_training_center_4,
  it_training_center_5: it_training_center_5,
  town_hall_1: town_hall_1,
  town_hall_2: town_hall_2,
  town_hall_3: town_hall_3,
  town_hall_4: town_hall_4,
  town_hall_5: town_hall_5,
  trading_center_1: trading_center_1,
  trading_center_2: trading_center_2,
  trading_center_3: trading_center_3,
  trading_center_4: trading_center_4,
  trading_center_5: trading_center_5,
  digital_mall_1: digital_mall_1,
  digital_mall_2: digital_mall_2,
  digital_mall_3: digital_mall_3,
  digital_mall_4: digital_mall_4,
  digital_mall_5: digital_mall_5,
  hackerspace_1: hackerspace_1,
  hackerspace_2: hackerspace_2,
  hackerspace_3: hackerspace_3,
  hackerspace_4: hackerspace_4,
  hackerspace_5: hackerspace_5,
  robo_advising_center_1: robo_advising_center_1,
  robo_advising_center_2: robo_advising_center_2,
  robo_advising_center_3: robo_advising_center_3,
  robo_advising_center_4: robo_advising_center_4,
  robo_advising_center_5: robo_advising_center_5,
  wellness_center_1: wellness_center_1,
  wellness_center_2: wellness_center_2,
  wellness_center_3: wellness_center_3,
  wellness_center_4: wellness_center_4,
  wellness_center_5: wellness_center_5,
  store_24h_1: store_24h_1,
  eatery_1: eatery_1,
  dormitory_1: dormitory_1,
  transformer_station_1: transformer_station_1,
  monument_1: monument_1,
  cyber_defense_center_1: cyber_defense_center_1,
  leisure_park_1: leisure_park_1,
  nft_gallery_1: NFT_gallery_1,
};

export const ACCENT_TYPE = ["positive", "negative", "neutral"] as const;

export const socialIconsSmallMap: { [key: string]: string } = {
  subscribe_telegram: tgIconSmall,
  join_group_telegram: tgIconSmall,
  subscribe_twitter: xIconSmall,
  subscribe_youtube: youtubeIconSmall,
  subscribe_twitch: twitchIcon,
  subscribe_instagram: instagramIconSmall,
  subscribe_facebook: facebookIcon,
  invite_friends_3: happyThreeFriendsIconSmall,
  invite_friends_15: fifteenFriendsIconSmall,
  invite_friends_30: fifteenFriendsIconSmall,
  open_url: openUrlIcon,
};

export const socialIconsBigMap: { [key: string]: string } = {
  subscribe_telegram: tgIconBig,
  join_group_telegram: tgIconBig,
  subscribe_twitter: xIconBig,
  subscribe_youtube: youtubeIconBig,
  subscribe_twitch: twitchIcon,
  subscribe_instagram: instagramIconBig,
  subscribe_facebook: facebookIcon,
  invite_friends_3: happyThreeFriendsIconBig,
  invite_friends_15: fifteenFriendsIconBig,
  invite_friends_30: fifteenFriendsIconBig,
  open_url: openUrlIcon,
};

export const socialTextMap = (t: TFunction): { [key: string]: string } => {
  return {
    subscribe_telegram: t("subscribe_telegram"),
    join_group_telegram: t("join_group_telegram"),
    subscribe_twitter: t("subscribe_twitter"),
    subscribe_youtube: t("subscribe_youtube"),
    subscribe_twitch: t("subscribe_twitch"),
    subscribe_facebook: t("subscribe_facebook"),
    subscribe_instagram: t("subscribe_instagram"),
    invite_friends_3: t("invite_friends_3"),
    invite_friends_15: t("invite_friends_15"),
    invite_friends_30: t("invite_friends_30"),
    open_url: t("open_link"),
  };
};

export const socialRewardConditionTextMap = (
  t: TFunction,
): { [key: string]: string } => {
  return {
    subscribe_telegram: t("subscribe_telegram"),
    join_group_telegram: t("join_group_telegram"),
    subscribe_twitter: t("subscribe_twitter"),
    subscribe_youtube: t("subscribe_youtube"),
    subscribe_twitch: t("subscribe_twitch"),
    subscribe_facebook: t("subscribe_facebook"),
    subscribe_instagram: t("subscribe_instagram"),
    invite_friends_3: t("invite_friends_3"),
    invite_friends_15: t("invite_friends_15"),
    invite_friends_30: t("invite_friends_30"),
    open_url: t("open_link"),
  };
};

// TODO: add descriptions translates and refactor translations
export const cityTaskNamingTextMap = (
  t: TFunction,
): { [key: string]: string } => {
  return {
    bank: t("cityTask.title.bank"),
    binary_market: t("cityTask.title.binary_market"),
    crypto_church: t("cityTask.title.crypto_church"),
    town_hall: t("cityTask.title.town_hall"),
    factory: t("cityTask.title.factory"),
    it_training_center: t("cityTask.title.it_training_center"),
    crypto_farm: t("cityTask.title.crypto_farm"),
    trading_center: t("cityTask.title.trading_center"),
    digital_mall: t("cityTask.title.digital_mall"),
    hackerspace: t("cityTask.title.hackerspace"),
    robo_advising_center: t("cityTask.title.robo_advising_center"),
    wellness_center: t("cityTask.title.wellness_center"),
    transformer_station: t("cityTask.title.transformer_station"),
    store_24h: t("cityTask.title.store_24h"),
    dormitory: t("cityTask.title.dormitory"),
    eatery: t("cityTask.title.eatery"),
    monument: t("cityTask.title.monument"),
    cyber_defense_center: t("cityTask.title.cyber_defense_center"),
    leisure_park: t("cityTask.title.leisure_park"),
    nft_gallery: t("cityTask.title.nft_gallery"),
  };
};

export const TURBO_BOOST_TIMER = 20000;
export const TAP_SEND_TIMER = 30000;

export const charactersMap: Record<
  string,
  {
    bigImg: string;
    smallImg: string;
    clickerImg: string;
    color: string;
    name: string;
    role: string;
  }
> = {
  zephyr: {
    name: "Zephyr",
    role: "chain runner",
    bigImg: zehphyrImg,
    smallImg: zephyrIcon,
    clickerImg: zephyrClicker,
    color: "#E3AE54",
  },
  hopper: {
    name: "Hopper",
    role: "whales crusher",
    bigImg: hopperImg,
    smallImg: hopperIcon,
    clickerImg: hopperClicker,
    color: "#4C8FC5",
  },
  tiko83: {
    name: "Tiko83",
    role: "TON-digger",
    bigImg: tiko83Img,
    smallImg: tiko83Icon,
    clickerImg: tiko83Clicker,
    color: "#E36653",
  },
};

export const getEventsMap = (
  t: TFunction,
): Record<
  string,
  {
    title: string;
    description: string;
    smallCover: string;
    hiddenCover: string;
  }
> => ({
  bull_market: {
    title: t("cityEvent.bull_market.title"),
    description: t("cityEvent.bull_market.description"),
    smallCover: bullMarketSmall,
    hiddenCover: bullMarketHidden,
  },
  bear_market: {
    title: t("cityEvent.bear_market.title"),
    description: t("cityEvent.bear_market.description"),
    smallCover: bearMarketSmall,
    hiddenCover: bearMarketHidden,
  },
  technological_breakthrough: {
    title: t("cityEvent.technological_breakthrough.title"),
    description: t("cityEvent.technological_breakthrough.description"),
    smallCover: technologicalBreakthroughSmall,
    hiddenCover: technologicalBreakthroughHidden,
  },
  blockchain_conference: {
    title: t("cityEvent.blockchain_conference.title"),
    description: t("cityEvent.blockchain_conference.description"),
    smallCover: blockchainConferenceSmall,
    hiddenCover: blockchainConferenceHidden,
  },
  hacker_attack: {
    title: t("cityEvent.hacker_attack.title"),
    description: t("cityEvent.hacker_attack.description"),
    smallCover: hackerAttackSmall,
    hiddenCover: hackerAttackHidden,
  },
  new_cryptocurrency_launch: {
    title: t("cityEvent.new_cryptocurrency_launch.title"),
    description: t("cityEvent.new_cryptocurrency_launch.description"),
    smallCover: newCryptocurrencyLaunchSmall,
    hiddenCover: newCryptocurrencyLaunchHidden,
  },
  cyber_defense_drill: {
    title: t("cityEvent.cyber_defense_drill.title"),
    description: t("cityEvent.cyber_defense_drill.description"),
    smallCover: cyberDefenseDrillSmall,
    hiddenCover: cyberDefenseDrillHidden,
  },
});

export const LOCALSTORAGE_KEYS_TO_KEEP = ["token"];
