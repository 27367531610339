/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { createEvent, createEffect, sample, createStore } from "effector";
import { $token } from "./auth";
import { ClickerState, Task } from "./types";

import restClient from "../api/rest-client";

export const fetchTasks = createEvent();
export const checkTask = createEvent<string>();
export const startTask = createEvent<string>();
export const closeTask = createEvent();
export const updateTaskLoadingMap = createEvent<{
  key: string;
  value: boolean;
}>();

export const $isCheckTaskLoading = createStore<Map<string, boolean>>(new Map());
export const $tasks = createStore<Task[]>([]);
export const $taskCanBeChecked = createStore(false);
export const $taskRewardRecieved = createStore(0);
export const $taskIdRewardRecieved = createStore("");

export const fetchTasksFx = createEffect<string, Task[]>(async () => {
  // const response = await fetch(`${envConfig.apiUrl}/clicker/list-tasks`, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${token}`,
  //   },
  // });
  const result = await restClient.post("clicker/list-tasks");
  return result.tasks;
});

interface StartTaskFxPayload {
  token: string;
  taskId: string;
}

// interface StartTaskFxReponse {

// }

export const startTaskFx = createEffect<StartTaskFxPayload, void>(
  async ({ taskId }) => {
    // const response = await fetch(`${envConfig.apiUrl}/clicker/start-task`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    //   body: JSON.stringify({
    //     taskId,
    //   }),
    // });

    await restClient.post("clicker/start-task", JSON.stringify({ taskId }));
  },
);

export const checkTaskFx = createEffect<
  { token: string; taskId: string },
  { clickerState: ClickerState; tasks: Task[] }
>(async ({ taskId }) => {
  // const response = await fetch(`${envConfig.apiUrl}/clicker/check-task`, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${token}`,
  //   },
  //   body: JSON.stringify({
  //     taskId,
  //   }),
  // });
  const result = await restClient.post(
    "clicker/check-task",
    JSON.stringify({ taskId }),
  );
  return result;
});

export const waitFx = createEffect<number, void>(async (delay) => {
  await new Promise((resolve) => setTimeout(resolve, delay));
});

sample({
  source: $token,
  clock: fetchTasks,
  target: fetchTasksFx,
});
sample({
  source: $token,
  clock: startTask,
  fn: (token, taskId) => ({ token, taskId }),
  target: [startTaskFx],
});

sample({
  clock: checkTask,
  fn: (task) => {
    return {
      key: task,
      value: true,
    };
  },
  target: [updateTaskLoadingMap],
});

sample({
  source: $isCheckTaskLoading,
  clock: updateTaskLoadingMap,
  fn: (currentMap, payload) => {
    const newMap = new Map(currentMap);
    newMap.set(payload.key, payload.value);
    return newMap;
  },
  target: $isCheckTaskLoading,
});

sample({
  clock: startTaskFx.doneData,
  fn: () => 5000,
  target: waitFx,
});
sample({
  clock: waitFx.done,
  fn: () => true,
  target: $taskCanBeChecked,
});
sample({
  clock: closeTask,
  target: [
    $taskCanBeChecked.reinit,
    $taskRewardRecieved.reinit,
    $taskIdRewardRecieved.reinit,
  ],
});
sample({
  source: $token,
  clock: checkTask,
  fn: (token, taskId) => ({ token, taskId }),
  target: checkTaskFx,
});
sample({
  source: startTaskFx.done,
  clock: checkTaskFx.doneData.map(({ tasks }) => tasks),
  filter: ({ params }, tasks) =>
    !!tasks.find((task) => task.id === params.taskId && task.completed),
  fn: ({ params }, tasks) => {
    const task = tasks.find((task) => task.id === params.taskId);
    return task?.reward || 0;
  },
  target: $taskRewardRecieved,
});
sample({
  source: startTaskFx.done,
  clock: checkTaskFx.doneData.map(({ tasks }) => tasks),
  filter: ({ params }, tasks) => {
    return !!tasks.find((task) => task.id === params.taskId && task.completed);
  },
  fn: ({ params }, tasks) => {
    const task = tasks.find((task) => task.id === params.taskId);

    return task?.id || "";
  },
  target: $taskIdRewardRecieved,
});
sample({
  clock: [
    fetchTasksFx.doneData,
    checkTaskFx.doneData.map(({ tasks }) => tasks),
  ],
  target: $tasks,
});

checkTaskFx.done.watch(({ params }) => {
  updateTaskLoadingMap({ key: params.taskId, value: false });
});
