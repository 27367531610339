import { createEffect } from "effector";
import { ClickerState, TurboTapPayload, TapPayload } from "./types";
import { TAP_SEND_TIMER } from "./constants";
// import envConfig from "../api/config";
import appStorage from "../module/app-storage";
import { StorageKeys } from "../module/app-storage/app-storage.constants";
import restClient from "../api/rest-client";

export interface TurboTapState {
  turboEarned: number;
  tapCount: number;
}

export const tapFx = createEffect<TapPayload, ClickerState>(
  async ({ currentEnergy, tapCount }) => {
    const requestDate = Date.now();
    const timestamp = Math.floor(Date.now() / 1000);
    // const response = await fetch(`${envConfig.apiUrl}/clicker/tap`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    //   body: JSON.stringify({
    //     currentEnergy,
    //     tapCount: tapCount,
    //     timestamp,
    //   }),
    // });
    // const data = await response.json();
    const data = await restClient.post(
      "clicker/tap",
      JSON.stringify({
        currentEnergy,
        tapCount,
        timestamp,
      }),
    );
    const responseDate = Date.now();
    return {
      ...data.clickerState,
      requestDate,
      responseDate,
    };
  },
);

export const turboTapFx = createEffect<
  TurboTapPayload,
  {
    clickerState: ClickerState;
    previousTurboTap: { turboEarned: number; tapCount: number };
  }
>(
  async ({
    currentEnergy,
    boostMultiplier,
    tapCount,
    coinsPerTap,
    isFinished,
  }) => {
    const requestDate = Date.now();
    const timestamp = Math.floor(Date.now() / 1000);
    // const response = await fetch(
    //   `${envConfig.apiUrl}/clicker/turbo-boosted-tap`,
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //     body: JSON.stringify({
    //       currentEnergy,
    //       tapCount,
    //       timestamp,
    //       boostMultiplier,
    //     }),
    //   },
    // );
    const data = await restClient.post(
      "clicker/turbo-boosted-tap",
      JSON.stringify({
        currentEnergy,
        tapCount,
        timestamp,
        boostMultiplier,
      }),
    );
    if (!isFinished) {
      throw new Error("Not the time to successfully finish");
    }
    const previousTurboTap = appStorage.getItem(
      StorageKeys.CurrentTurboTap,
    ) as unknown as TurboTapState;
    const previousTurboTapParsed: TurboTapState = previousTurboTap
      ? previousTurboTap
      : { turboEarned: 0, tapCount: 0 };
    const turboEarned = Math.floor(tapCount * coinsPerTap * boostMultiplier);
    appStorage.setItem(StorageKeys.CurrentTurboTap, { turboEarned, tapCount });
    appStorage.setItem(StorageKeys.LastTurboTap, previousTurboTapParsed);
    return {
      clickerState: { ...data.clickerState, requestDate },
      previousTurboTap: previousTurboTapParsed as {
        turboEarned: number;
        tapCount: number;
      },
    };
  },
);

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const startTapTimerFx = createEffect<void, unknown>(
  () => new Promise((rs) => setTimeout(rs, TAP_SEND_TIMER)),
);
