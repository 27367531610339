import i18n, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import { setLanguage } from "../state/state.ts";
import { createStore } from "effector";
import { Deutch, English, French, Russian, Spanish } from "../locales/index.ts";
import appStorage from "../module/app-storage/index.ts";
import { StorageKeys } from "../module/app-storage/app-storage.constants.ts";

export const $language = createStore("en");

export enum Language {
  Russian = "ru",
  Deutch = "de",
  English = "en",
  Spanish = "es",
  French = "fr",
}

const resources = {
  [Language.Russian]: {
    translation: Russian,
  },
  [Language.Deutch]: {
    translation: Deutch,
  },
  [Language.English]: {
    translation: English,
  },
  [Language.Spanish]: {
    translation: Spanish,
  },
  [Language.French]: {
    translation: French,
  },
};

const supportedLngs: string[] = [
  Language.Russian,
  Language.Deutch,
  Language.English,
  Language.Spanish,
  Language.French,
];

const i18nextConfig: InitOptions = {
  resources,
  supportedLngs,
  fallbackLng: Language.English,
  keySeparator: ".",
  react: { useSuspense: false },
  interpolation: {
    escapeValue: false,
  },
  debug: true,
};

export const initI18n = async () => {
  let defaultLanguage: string = Language.English;
  const storagedLang = appStorage.getItem(StorageKeys.Lang) as unknown as
    | string
    | null;

  if (typeof storagedLang === "string") {
    defaultLanguage = storagedLang;
  } else if (
    window.Telegram.WebApp.initDataUnsafe.user?.language_code !== undefined &&
    supportedLngs.includes(
      window.Telegram.WebApp.initDataUnsafe.user.language_code,
    )
  ) {
    defaultLanguage = window.Telegram.WebApp.initDataUnsafe.user.language_code;
  }

  if (defaultLanguage) {
    i18nextConfig.lng = defaultLanguage;
  }
  await i18n.use(initReactI18next).init(i18nextConfig, () => {
    setLanguage(defaultLanguage);
  });
};

$language.on(setLanguage, (_, language) => {
  if (supportedLngs.includes(language as Language)) {
    return language;
  }
});

setLanguage.watch(async (language: string) => {
  if (i18n.language !== language) {
    await i18n.changeLanguage(language);
    appStorage.setItem(StorageKeys.Lang, language);
  }
});
