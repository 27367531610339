import { NavigateFunction } from "react-router-dom";

export const backButtonEnable = (navigate: NavigateFunction) => {
  window.Telegram.WebApp.BackButton.show();
  window.Telegram.WebApp.BackButton.onClick(() => {
    window.Telegram.WebApp.BackButton.hide();
    navigate("/game");
  });
};

export const lightTgTap = () => {
  window.Telegram &&
    window.Telegram.WebApp.HapticFeedback.impactOccurred("light");
  if ("vibrate" in navigator) {
    navigator.vibrate(50);
  }
};
