import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";
import { NotFound } from "./NotFound";

const ErrorFallback = () => {
  return <NotFound />;
};

export const ErrorBoundary = () => {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback}>
      <Outlet />
    </ReactErrorBoundary>
  );
};
